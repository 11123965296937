import { Trans } from "react-i18next";

type Props = {
  illustration: { src: string; alt: string };
  title: string;
  description?: string;
};

export function ErrorLayout({ illustration, title, description }: Props) {
  return (
    <>
      <section className="light:bg-white light:text-neutral-900 dark:bg-neutral-septenary dark:text-white">
        <div className="max-w-xl mx-auto text-center pb-32 md:pb-60 px-4 pt-12">
          <img
            src={illustration.src}
            alt={illustration.alt}
            className="md:w-[220px] w-32 mx-auto rounded-full"
          />

          <h1 className="font-display text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-12 font-bold mt-10 mb-6">
            {title}
          </h1>

          {description && description.trim().length > 0 && (
            <Trans
              className="text-base leading-7 text-accentTeal-600"
              defaults={description}
              parent="p"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                home: <a href="/" className="underline" />,
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                help: <a href="/help" className="underline" />,
              }}
            />
          )}
        </div>
      </section>
    </>
  );
}
